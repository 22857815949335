export const contactList = [
  {
    idx: "1",
    name: "Lael Han",
    phone: "250-328-4570",
    date: "2021-07-11",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "2",
    name: "Robin Lee",
    phone: "250-328-3452",
    date: "2021-07-11",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "3",
    name: "Kevin Kim",
    phone: "250-328-4570",
    date: "2020-06-21",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "4",
    name: "Youmi Won",
    phone: "250-324-4643",
    date: "2021-04-12",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "5",
    name: "Jessi Kim",
    phone: "111-328-2221",
    date: "2021-02-13",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "6",
    name: "Cony Si",
    phone: "250-328-2214",
    date: "2021-01-16",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "7",
    name: "David Han",
    phone: "250-328-1111",
    date: "2021-03-17",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "8",
    name: "Lael Dgdf",
    phone: "250-328-5555",
    date: "2021-01-12",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "9",
    name: "Lael Htdf",
    phone: "250-328-6666",
    date: "2021-04-17",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "10",
    name: "Lael Fewe",
    phone: "250-328-4570",
    date: "2021-04-14",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "11",
    name: "Lael Vdse",
    phone: "250-328-2323",
    date: "2021-02-13",
    descClaim: "TestData",
    consideration: "full-refund",
  },
  {
    idx: "12",
    name: "unknown",
    phone: "250-487-9729",
    date: "unknown",
    descClaim: "stomach pain symptoms",
    consideration: "full-refund",
  },
  {
    idx: "13",
    name: "unknown",
    phone: "778-242-1336",
    date: "unknown",
    descClaim: "found the hair",
    consideration: "full-refund",
  },
  {
    idx: "14",
    name: "unknown",
    phone: "250-493-4700",
    date: "unknown",
    descClaim: "not enough shrimp",
    consideration: "full-refund",
  },
];
