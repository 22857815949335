import React from "react";
import image from "../assets/Free.jpg";

const Pricing = () => {
  return (
    <div className="mt-3">
      <img src={image} className="img-fluid rounded mx-auto d-block" />
    </div>
  );
};
export default Pricing;
